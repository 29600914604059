<template>
  <div id="bonusEdit">
    <bonus-admin-form
      v-if="isAdmin"
      :bonus="BonusAdmin"
      @canceled="toView()"
      @saved="toView()"
      @error="error($event)"
    />
    <bonus-affiliate-form
      v-if="!isAdmin"
      :bonus="BonusAffiliate"
      @canceled="toView()"
      @saved="toView()"
      @error="error($event)"
    />
  </div>
</template>

<script>
import BonusAdminForm from '@/components/bonus_admin/BonusForm'
import BonusAffiliateForm from '@/components/bonus_affiliate/BonusForm'
import Utils from '@/utils'
export default {
  components: {
    BonusAdminForm,
    BonusAffiliateForm,
  },
  data() {
    return {
      BonusAdmin: {
        requested_amount: 0,
        bonus_amount: 0,
        return_amount: 0,
        payment_method: 0,
      },
      BonusAffiliate: {
        requested_amount: 0,
        bonus_amount: 0,
        return_amount: 0,
        payment_method: 0,
      },
    }
  },
  created() {
    if (this.axios.defaults.headers.common.Authorization) {
      if (this.isAdmin) {
        this.axios
          .get(this.$api('/bonus_list/' + this.$route.params.id))
          .then(({ data }) => {
            this.BonusAdmin = data
            this.BonusAdmin.order_date = Utils.formatDateReverse(
              this.BonusAdmin.order_date
            )
            var aux = []
            this.BonusAdmin.dependents.forEach(dependent => {
              aux.push(dependent.id)
            })
            this.BonusAdmin.dependents = aux
          })
          .catch(error => console.log(error.response))
      }
      if (!this.isAdmin) {
        this.axios
          .get(this.$api('/affiliate_bonus_list/' + this.$route.params.id))
          .then(({ data }) => {
            this.BonusAffiliate = data
            this.BonusAffiliate.order_date = Utils.formatDateReverse(
              this.BonusAffiliate.order_date
            )
            var aux = []
            this.BonusAffiliate.dependents.forEach(dependent => {
              aux.push(dependent.id)
            })
            this.BonusAffiliate.dependents = aux
          })
          .catch(error => console.log(error.response))
      }
    }
  },
  computed: {
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    toView() {
      this.$router.push({
        name: 'bonus.show',
        params: { id: this.$route.params.id },
      })
    },
    error(error) {
      console.log(error)
    },
  },
}
</script>
